@import "../colors.scss";

.singleInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sInput {
    width: 30%;
  }
  .css-1tcs2i1-MuiInputBase-root-MuiInput-root::after {
    border-bottom-color: $red;
  }
}
