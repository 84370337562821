@import '../../colors.scss';
@import '../../mixin.scss';

.modelAComponent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom:57px;
  background: url(../../assets/Indigo.png);
  background-repeat: no-repeat;
  background-position: 0 450px;
  background-size: 100%;

  .topContainer {
    width: 95%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    margin: auto;

    .modelAPageTitle {
      font-size: 31px;
      font-weight: 600;
      line-height: 37px;
      text-align: left;
      color: #000000;
      margin-top: 44px;
      margin-bottom: 56px;
    }
  }

  .mainContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .modelAChartContainer {
    width: 100%;
    .reset {
      color: $white;
      background-color: $red ;
      text-transform: capitalize;
      width: 15%;
      margin: 1rem 0rem;
    }
  }

  .modelATopHeaderTitle,
  .modelAHeaderTitle {
    text-align: center;
    margin: 0;
    text-transform: uppercase;
  }

  .modelAHeaderTitle {
    width: 100%;

  }

  .modelATopHeaderTitle {
    width: 100%;
    font-size: 20px;
    color: $gray;

  }

  .redBorder {
    @include titleBorders;
    color: $black;
  }

  .orangeBorder {
    border-bottom: solid 3px $orange;
    color: $gray;
  }

  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .showResultsButtonContainer {
      width: 100%;
      align-self: flex-end;
      margin-top: 1rem;

      .inputNotification {
        color: $red;
        width: 100%;
        display: block;
        text-align: center;
        margin-bottom: 5px;
      }

      .calculate {
        width: 100%;
        align-self: flex-end;
        background-color: $red;
        color: $white;
        text-transform: capitalize;
      }
    }
  }

  .modelAMapContainer {
    width: 100%;
    height: 505px;

    .continentContainer {
      width: 45%;
      display: flex;
      justify-content: space-evenly;

      p {
        padding: 0rem 1rem;
        border-bottom: solid 3px $orange ;
        cursor: pointer;
        transition: all .3s;
        @include tabsAndChartsTitle;
        margin: 0;
        text-transform: uppercase;


        &:hover {
          @include titleBorders;
          transform: scale(1.1);
        }
      }
    }
  }

  .chartTitle {
    color: $black;
    border-radius: 5px;
    text-transform: uppercase;
    @include tabsAndChartsTitle;
  }

  .chartContainer {
    position: relative;

    .exportBtn {
      position: absolute;
      right: 2%;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid $red; 
    }

    .cardHeader {
      height: 30%;
    }

    .rangeBarEmployment {
      height: 20%;
    }
  }

  .modelATabsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bolder {
      font-weight: bolder;
    }

    .modelALeftTab,
    .modelARightTab {
      @include tabsAndChartsTitle;
      width: 49%;
      padding-bottom: 0.4rem;
    }
  }

  .chartTitleDescription {
    color: $gray;
    @include chartTexts;
    display: inline-block;
    margin-bottom: 1rem;
  }

  .rangeBar {
    height: 495px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rangeBarEmployment {
    color: $red;
    font-weight: 600;
    font-size: 40px;
  }

  .MuiTabs-indicator {
    background-color: $red !important;
    border: solid 3px $red;
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: -1rem;
    color: rgba($black, 0.70);

    @include chartTexts;

    .rect {
      width: 15px;
      height: 15px;
    }

    span {
      margin-right: 4px;
    }

    .blackRect,
    .blackRectOpa30,
    .blackRectOpa60,
    .redRect,
    .redRectOpa60,
    .redRectOpa30 {
      width: 15px;
      height: 15px;
    }

    .blackRect {
      background-color: $black;
    }

    .blackRectOpa60 {
      background-color: rgba($black, 0.60);
    }

    .blackRectOpa30 {
      background-color: rgba($black, 0.30);
    }

    .redRect {
      background-color: $red;
    }

    .redRectOpa60 {
      background-color: rgba($red, 0.60);
    }

    .redRectOpa30 {
      background-color: rgba($red, 0.30);
    }
  }
}

@media only screen and (min-width: 1300px) {
  .modelAComponent {
    .topContainer {
      .modelALeftContainer {
        width: 55%;
      }

      .modelARightContainer {
        width: 44%;
      }
    }

    .modelAMapContainer {
      .continentContainer {
        width: 30%;
      }
    }
  }
}

@media only screen and (min-width: 1570px) {
  .modelAComponent {
    .topContainer {
      .modelALeftContainer {
        width: 60%;
      }

      .modelARightContainer {
        width: 39%;
      }
    }
  }
}
