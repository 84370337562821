@import "../../../colors.scss";
@import '../../../mixin.scss';

.modelBTaxesGroupComponent {
  width: 100%;
  height: 100%;

  .leviesGroupRadio {
    width: 100%;

    .singleRadioGroup {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .fixedLevyInput {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .fixedLevyContainer{
      width: 40%;
      .sInput{
        width: 30%;
      }
      .perLt{
        width: 180px;
      }
    }
  }

  .leviesInputGroups {
    width: 100%;

    .taxesInput {
      width: 40%;
      display: flex;

      .perLt {
        display: inline-block;
        width: 180px;
      }
    }

    .variableLevyRow {
      display: flex;
      align-items: center;
      justify-content: space-between
    }
  }

  .taxHeader {
    .taxesTitle {
      @include titleBorders;
      text-transform: uppercase;
      @include tabsAndChartsTitle;
      text-align: center;
    }
  }

  .leviesGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .taxesDescription {
      width: 34%;
    }




    .singleRadioGroup {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 30%;
    }
  }
}