@import './colors.scss';
@mixin titleBorders 
{
    border-bottom: solid 3px $red;
}
@mixin chartTexts 
{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: rgba(71, 71, 71, 1);
    
}
@mixin tabsAndChartsTitle
{
    font-size: 18px;
}
@mixin titlesHomePage 
{
    border-bottom: solid 3px $chartThirth;
}
@mixin titlesHomePageHover 
{
    @include titleBorders;
}
