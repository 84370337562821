@import "../../../colors.scss";
@import '../../../mixin.scss';

.modelbElasticityGroupComponent{
    width: 100%;
    line-height: 6px;
    display: flex;
    flex-direction: column;
    .elasticityCategoryRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .categoryInput{
        width: 20%;
    }
}