@import '../colors.scss';

.header {
  width: 100%;
  background-color: $red;
  border-bottom: solid 3px $modelAHeaderBorder;
  font-weight: 600;
  height: 64px;
  display: flex;
  align-items: center;

  .headerContainer {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin: 12px 48px;
    width: 100%;

    .navLogo {
      width: 90px;
      height: auto;

    }
  }
}

.breadcrumb {
  color: $black ;

  a {
    color: $black ;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      color: $red !important;
    }
  }
}

.breadCumbsLink {
  margin-right: auto;
}

.headerLanguageGroup {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  gap: 16px;

  .headerBtn {
    background-color: transparent ;
    border-color: $white ;
    color: $white ;
    font-weight: 600;
    font-size: 14px;
    padding: 8px 22px;
  }
}
