@import '../colors.scss';
@import '../mixin.scss';

.bubbles {
  min-height: 93vh;
}

.homeComponent {
  width: 100%;
  display: grid;
  place-items: center;
  flex: 1;
}

.modelsContainer {
  width: 100%;
  min-height: 626px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 230px;
}

.modelContainerA,
.modelContainerB {
  width: 30%;
  margin: 0 2rem;
  transition: all 0.4s;
  text-decoration: none;

  &:hover {
    transform: scale(1.02);
    @include titlesHomePageHover;
  }
}

.modelContainerA {
  @include titlesHomePage;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50px;
  }
}

.modelContainerB {
  border-bottom: solid 3px $orange;
}

.model {
  padding: 1rem 1rem;
  font-weight: 600;
  display: block;
  text-align: center;
  font-size: 19px;
  text-transform: capitalize;
}

.modelA {
  color: $black;
}

.modelB {
  color: $black
}

.modelDescription {
  background-color: $modelsDescriptionBackGround;
  color: $modelsDescriptionTextColor;
  padding: 1rem;
  line-height: 2;
  letter-spacing: 1px;
}
