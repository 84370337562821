@import "../../../colors.scss";

.modelAInputGroupComponent {
  padding: 2rem 0rem;
  width: 100%;

  .singleRowComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .emptyDiv,
    .taxRateTitle {
      width: 45%;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;

    }

    .taxInputGroup {
      width: 95%;
      .inputNotification
      {
        font-size: 13px;
        color:$red;

        width: 100%;
        display: block;
        text-align: center;
        margin-top: 5px;
      }
    }

    .headerTitle {
      width: 30%;
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;

    }

    .noCalorie {
      width: 19%;
    }

    .regular {
      width: 16.7%;
    }

    .checkboxContainer,
    .checkboxContainerBestEstimates {
      width: fit-content;
      justify-self: end;
      margin: 1rem 0rem;
      margin-left: auto;
    }

    .FC {
      width: 49%;
    }

    .IC {
      width: 49%;
    }

    .elasticityInputGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sInput {
        width: 49%;
      }
    }
  }
}