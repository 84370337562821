html {
  scroll-behavior: smooth;
}

.bubbles {
  width: 100%;
  background-image: url(./assets/bubbles.png) , url(./assets/bubbles.png);
  background-position: calc(100% - 64px) 64px , 157px 300px; 
  background-repeat: no-repeat , no-repeat;
}
