@import '../../colors.scss';
@import '../../mixin.scss';

.modelBComponent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom:57px;
  background: url(../../assets/Indigo.png);
  background-repeat: no-repeat;
  background-position: 0 450px;
  background-size: 100%;

  .topContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap:24px;
    padding: 0 152px;

    .modelBPageTitle {
      font-size: 31px;
      font-weight: 600;
      line-height: 37px;
      text-align: left;
      color: #000000;
      margin-top: 44px;
      margin-bottom: 0;

    }

    .modelBLeftContainer {
      width: 45%;

      .mapTitle {
        text-align: center;
        @include titleBorders;
        @include tabsAndChartsTitle;
        text-transform: uppercase;
      }
    }

    .modelBRightContainer {
      width: 100%;

      .middleContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .taxesGroupContainer {
          width: 54%;

          .modelBInputTitle {
            text-align: center;
            @include titleBorders;
            width: 100%;
            @include tabsAndChartsTitle;
            text-transform: uppercase;
          }

          .elasticity {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }


        .taxedNutrientsContainer {
          width: 45%;

          .modelBInputTitle {
            text-align: center;
            @include titleBorders;
            width: 100%;
            @include tabsAndChartsTitle;
            text-transform: uppercase;
          }

          .radioGroupContainer {
            height: 50px;

            .radioGroup {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .taxesCategoriesContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }

  .modelBtitle {
    text-align: center;
    @include titleBorders;
    width: 100%;
  }

  .modelBMapContainer {
    display: flex;
    width: 100%;
    height: 700px;
  }

  .calculateButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    margin: auto;
    margin-top: 1rem;

    .inputNotification {
      color: $red;
    }

    .calculate {
      width: 116px;
      background-color: $red;
      color: $white;
      text-transform: capitalize;
    }
  }

  .chartsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .legend {
      @include chartTexts
    }
  }

  .MuiTab-root {
    @include tabsAndChartsTitle;
  }

  .reset {
    align-self: flex-end;
    width: 15%;
    background-color: $red;
    color: $white;
    text-transform: capitalize;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    margin-top: -0.2rem;

    &:hover {
      background-color: $red;
    }
  }

  .tabContainer {
    text-align: center;
    display: flex;
    justify-content: space-around;
    font-weight: 600;
  }

  .tabChartsWrapper {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    gap: 10px;
    .tabChartColumnContainerSingle {
      padding: 0.5rem;
      width: 50%;
      border-radius: 6px;
      border: 1px solid rgba(185, 185, 185, 0.404);
      margin: auto;
    }

    .tabChartColumnContainer {
      padding: 0.5rem;
      width: 50%;
      border-radius: 6px;
      border: 1px solid rgba(185, 185, 185, 0.404);
      margin: auto;
    }
  }

  .chartContainer {
    position: relative;
    .exportBtn {
      position: absolute;
      right: 2%;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid $red; 
    }
    .cardHeader {
      height: 30%;
    }
    .rangeBarEmployment {
      height: 20%;
    }
  }


  .chartTitleDescription {
    color: $modelBChartTitleDescription;
    @include chartTexts
  }

  .chartTitle {
    color: $black;
    border-radius: 5px;
    text-transform: uppercase;
    margin: 0px;
    @include tabsAndChartsTitle;
    position: relative;

    .exportBtn {
      position: absolute;
      right: 2%;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid $red; 
    }
  }

  .MuiTabs-indicator {
    background-color: $red !important;
    border: solid 2px $red;
  }

  .legend {
    margin-top: -2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px; 

    .rect {
      width: 15px;
      height: 15px;
    }

    span {
      margin-right: 4px;
    }

    .redRect,
    .orangeRect,
    .blackRect,
    .redRectOpa60,
    .redRectOpa30 {
      width: 15px;
      height: 15px;
    }

    .redRect {
      background-color: $red;
    }

    .orangeRect {
      background-color: $orange;
    }

    .blackRect {
      background-color: $black;
    }

    .redRectOpa60 {
      background-color: rgba($red, 0.60);
    }

    .redRectOpa30 {
      background-color: rgba($red, 0.30);
    }
  }
}

@media only screen and (min-width: 1200px) {
  .modelBComponent {
    .topContainer {
      .modelBLeftContainer {
        width: 45%;
      }

      .modelBRightContainer {
        width: 100%;
      }
    }

    .modelBMapContainer {

      .continentContainer {
        width: 30%;
      }
    }
  }
}

@media only screen and (min-width: 1570px) {
  .modelBComponent {
    .topContainer {
      .modelBLeftContainer {
        width: 50%;
      }

      .modelBRightContainer {
        width: 100%;
      }
    }
  }
}
