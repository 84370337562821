//Coca Cola Colors
$black: #1E1E1E;
$red: #F40009;
$white: #FFFFFF;
$gray:#A7A9AC;
$orange:#E98300;
//Charts Color
$chartPrimary: #F40009;
$chartSecondary: #6A1F4A;
$chartThirth: #E98300;
//Home Page
$modelsBackGround: $red;
$modelsDescriptionBackGround: $white;
$modelsDescriptionTextColor: rgb(34, 34, 34);

//Login Page
$loginCardBackGround: $black;
$loginCardShadow: $white;

//Model A Page
$modelAHeaderBorder: $red;
$modelAChartTitleBackGround: $black;

//Model B Page
$modelBChartTitleBackGround: $black;
$modelBChartTitleDescription: gray;

//SideBar
$sideBarBackGround: $white;
$sideBarTitleBorder: $modelAHeaderBorder;
$sideBarTitleText: $black;

//Components
$calculateButtonBackGround: $red;
$modelAInputGroupsSideBarTitleText: $sideBarTitleText;
$modelAInputGroupsSideBarTitleBorder: $white;