@import '../colors.scss';

.footer {
    width: 100%;
    background-color: $red;
    padding: 64px 48px;
    .footerContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: auto;
    
        .footerLogo {
          width: 180px;
          height: auto;
        }

        .footerSubtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: $white;
        }
      }
}