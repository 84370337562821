@import './colors.scss';

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.logoutLanguageGroup {
  display: flex;
}
