@import '../../../colors.scss';
@import '../../../mixin.scss';

.modelBCategoryGroupComponent {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
  height: 100%;


  .selectAllContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .modelBInputTitle {
    text-align: center;
    @include titleBorders;
    width: 100%;
    @include tabsAndChartsTitle;
    text-transform: uppercase;
  }

  .selected {
    color: $red;
    font-weight: 600;
  }
}